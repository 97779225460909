/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, ContactForm, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="css-dklbmc css-1gvlxx8 --style2 --full --parallax" anim={""} name={"uvod"} animS={"3"} border={""} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/1b9aaf54cf724163a2abf5e0ed2af77f_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/1b9aaf54cf724163a2abf5e0ed2af77f_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/1b9aaf54cf724163a2abf5e0ed2af77f_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/1b9aaf54cf724163a2abf5e0ed2af77f_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/1b9aaf54cf724163a2abf5e0ed2af77f_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/1b9aaf54cf724163a2abf5e0ed2af77f_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/1b9aaf54cf724163a2abf5e0ed2af77f_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/1b9aaf54cf724163a2abf5e0ed2af77f_s=3000x_.jpg);
    }
  
background-position: 50% 48%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1612}} content={"<span style=\"color: rgba(255, 255, 255, 0.63);\">Kontakt</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--40" name={"8buv8xitpkb"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/965c3e40d27d46d385ed085e1b11d2fa.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Title className="title-box fs--43" content={"<span style=\"font-weight: bold;\">+420 777 770 699<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/a2c109360cbd4b5fa3ef0623ea65cfca.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Title className="title-box" content={"stella@atelierstod.cz<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/bd9f8784d70b4b548d89a0b10febe8e8.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Title className="title-box" content={"Plzeňský kraj<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10" name={"kontakt"} style={{"paddingTop":12,"backgroundColor":"rgba(206,178,162,1)"}} css={css`
      background-position: 51% 67%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontaktovat"}>
              </Title>

              <ContactForm className="--shape2 --style1 ff--2 fs--18 w--300 mt--30" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Souhlasím se zpracováním osobních údajů","type":"checkbox","required":true},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20" name={"patka"} style={{"paddingTop":35,"backgroundColor":"rgba(224, 224, 224, 1)"}} layout={"l1"} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Ateliér Tůtojc Stod"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":394}} content={"Stella Jarošová<br>IČO:&nbsp;08663696<br>Sídlo:\nMířovice 10,\n333 01 Ves Touškov<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/4546/6994423283334a92aa761f455601a59b_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":398}} srcSet={"https://cdn.swbpg.com/t/4546/6994423283334a92aa761f455601a59b_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}